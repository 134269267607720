import React, { useState, useMemo } from 'react'
import { graphql, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import Search from 'components/UIElements/Search'
// Models
import { IOptionalMetaProps, ISeoQuery } from 'models/SEO'

type IndexPageProps = {
    pageSeo: ISeoQuery
}

const IndexPage: React.FC<PageProps<IndexPageProps>> = ({ data }) => {
    const [showBackdrop, setShowBackdrop] = useState<boolean>(false)
    const pageSeo = data.pageSeo
    const seo: IOptionalMetaProps = {
        title: pageSeo.title,
        description: pageSeo.description.text,
        image: pageSeo.image.file?.url,
    }

    const openBackdrop = () => setShowBackdrop(true)
    const closeBackdrop = () => setShowBackdrop(false)

    const searchIndices = [
        { name: `Songs`, title: `Canciones` },
        { name: `Discography`, title: `Discografía` },
        { name: `Articles`, title: `Articulos` },
        { name: `Interviews`, title: `Entrevistas` },
        { name: `Artists`, title: `Artistas` },
        { name: `Users`, title: `Usuarios` },
    ]

    return (
        <Layout seo={seo}>
            <span
                style={{
                    padding: '1rem',
                    backgroundColor: '#007eff',
                    color: 'white',
                }}
                onClick={openBackdrop}
            >
                Abrir backdrop
            </span>
            <Search
                show={showBackdrop}
                onClose={closeBackdrop}
                indices={searchIndices}
            />
        </Layout>
    )
}

export default IndexPage

export const pageQuery = graphql`
    query SearchPageQuery {
        pageSeo: contentfulSeo(slug: { eq: "home" }) {
            title
            description {
                text: description
            }
            image {
                gatsbyImageData
                file {
                    url
                }
            }
        }
    }
`
